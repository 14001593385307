.App {
  text-align: center;
  background-color: #000000;
  background-image: url("pluto_small.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.App-header h1 {
  font-size: calc(10px + 8vmin);
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #688690;
  color: white;
}
